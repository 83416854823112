<template>
<div id="loaderParent" v-if="loaded!==true && loadedIterationCount<1">
  <div id="Loading">
    <svg width="100%" height="100%" viewBox="0 0 3150 3150" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <rect id="Artboard1" x="0" y="0" width="3149.61" height="3149.61" style="fill:none;" />
      <g id="Artboard11" serif:id="Artboard1">
        <g transform="matrix(8.0335e-17,1.31197,-1.31197,8.0335e-17,3522.8,-485.665)">
          <g transform="matrix(1,0,0,1,-104.619,-229.322)">
            <path d="M1644.21,1077.95C1666,1065.37 1692.85,1065.37 1714.63,1077.95C1827.28,1142.99 2178.05,1345.5 2290.7,1410.54C2312.49,1423.12 2325.92,1446.37 2325.92,1471.53L2325.92,2136.72C2325.92,2161.88 2312.49,2185.13 2290.7,2197.71C2178.05,2262.75 1827.28,2465.26 1714.63,2530.3C1692.85,2542.88 1666,2542.88 1644.21,2530.3C1531.56,2465.26 1180.79,2262.75 1068.14,2197.71C1046.35,2185.13 1032.93,2161.88 1032.93,2136.72L1032.93,1471.53C1032.93,1446.37 1046.35,1423.12 1068.14,1410.54C1180.79,1345.5 1531.56,1142.99 1644.21,1077.95Z" style="fill:var(--secondary);" />
            <clipPath id="_clip1">
              <path d="M1644.21,1077.95C1666,1065.37 1692.85,1065.37 1714.63,1077.95C1827.28,1142.99 2178.05,1345.5 2290.7,1410.54C2312.49,1423.12 2325.92,1446.37 2325.92,1471.53L2325.92,2136.72C2325.92,2161.88 2312.49,2185.13 2290.7,2197.71C2178.05,2262.75 1827.28,2465.26 1714.63,2530.3C1692.85,2542.88 1666,2542.88 1644.21,2530.3C1531.56,2465.26 1180.79,2262.75 1068.14,2197.71C1046.35,2185.13 1032.93,2161.88 1032.93,2136.72L1032.93,1471.53C1032.93,1446.37 1046.35,1423.12 1068.14,1410.54C1180.79,1345.5 1531.56,1142.99 1644.21,1077.95Z" />
            </clipPath>
            <g clip-path="url(#_clip1)">
              <g transform="matrix(0.660095,-0.381106,0.381106,0.660095,108.463,1322.93)">
                <path d="M1533.23,1485.86L1951.07,728.893C1952.92,725.538 1957.15,724.317 1960.51,726.169C1963.86,728.021 1965.08,732.249 1963.23,735.605L1524.45,1530.51C1515.69,1566.51 1517.42,1601.01 1529.7,1639.86L1963.23,2425.26C1965.08,2428.61 1963.86,2432.84 1960.51,2434.69C1957.15,2436.54 1952.92,2435.32 1951.07,2431.97L1538.19,1683.99C1494.45,1623.68 1463.07,1598.02 1413.03,1587.38L496.062,1587.38C492.227,1587.38 489.118,1584.27 489.118,1580.43C489.118,1576.6 492.227,1573.49 496.062,1573.49L1380.18,1573.49C1459.64,1565.36 1489.21,1547.44 1533.23,1485.86Z" style="fill:var(--background);stroke:var(--background)" />
              </g>
              <g transform="matrix(4.6672e-17,-0.762212,0.762212,4.6672e-17,474.799,2914.44)">
                <path d="M1533.23,1485.86L1951.07,728.893C1952.92,725.538 1957.15,724.317 1960.51,726.169C1963.86,728.021 1965.08,732.249 1963.23,735.605L1524.45,1530.51C1515.69,1566.51 1517.42,1601.01 1529.7,1639.86L1963.23,2425.26C1965.08,2428.61 1963.86,2432.84 1960.51,2434.69C1957.15,2436.54 1952.92,2435.32 1951.07,2431.97L1538.19,1683.99C1494.45,1623.68 1463.07,1598.02 1413.03,1587.38L496.062,1587.38C492.227,1587.38 489.118,1584.27 489.118,1580.43C489.118,1576.6 492.227,1573.49 496.062,1573.49L1380.18,1573.49C1459.64,1565.36 1489.21,1547.44 1533.23,1485.86Z" style="fill:var(--primary);" />
              </g>
            </g>
          </g>
          <g>
            <g id="c1">
              <path d="M1659.24,1069.27C1671.73,1062.06 1687.11,1062.06 1699.6,1069.27C1792.96,1123.17 2183.22,1348.49 2295.55,1413.34C2301.81,1416.95 2305.67,1423.64 2305.67,1430.87C2305.67,1438.11 2301.81,1444.79 2295.55,1448.41C2183.22,1513.26 1792.96,1738.57 1699.6,1792.48C1687.11,1799.68 1671.73,1799.68 1659.24,1792.48C1565.88,1738.57 1175.63,1513.26 1063.3,1448.41C1057.03,1444.79 1053.17,1438.11 1053.17,1430.87C1053.17,1423.64 1057.03,1416.95 1063.3,1413.34C1175.63,1348.49 1565.88,1123.17 1659.24,1069.27Z" />
            </g>
            <g id="b1">
              <path d="M1679.42,1827.43C1679.42,1813.01 1687.11,1799.68 1699.6,1792.48C1792.96,1738.57 2183.22,1513.26 2295.55,1448.41C2301.81,1444.79 2309.53,1444.79 2315.79,1448.41C2322.06,1452.02 2325.92,1458.71 2325.92,1465.94C2325.92,1595.65 2325.92,2046.27 2325.92,2154.08C2325.92,2168.5 2318.22,2181.82 2305.74,2189.03C2212.38,2242.93 1822.12,2468.24 1709.79,2533.1C1703.53,2536.72 1695.81,2536.72 1689.55,2533.1C1683.28,2529.48 1679.42,2522.8 1679.42,2515.56L1679.42,1827.43Z" />
            </g>
            <g id="a1">
              <path d="M1032.93,1465.94C1032.93,1458.71 1036.79,1452.02 1043.05,1448.41C1049.32,1444.79 1057.03,1444.79 1063.3,1448.41C1175.63,1513.26 1565.88,1738.57 1659.24,1792.48C1671.73,1799.68 1679.42,1813.01 1679.42,1827.43L1679.42,2515.56C1679.42,2522.8 1675.56,2529.48 1669.3,2533.1C1663.03,2536.72 1655.32,2536.72 1649.05,2533.1C1536.72,2468.24 1146.47,2242.93 1053.11,2189.03C1040.62,2181.82 1032.93,2168.5 1032.93,2154.08L1032.93,1465.94Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
    <div class="message" v-if="message">{{message}}</div>
    <div id="loaderBar" v-if="loadedPercent!==false" :style="'background-position:'+(Math.ceil(loadedPercent*100)-100)+'vw 50%;'"></div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LoadingElement',
  data(){ 
    return {
      loading: false,
      message:false,
    }
  },
  created() {
    this.loading = true
    // let ts = this;
    
    this.$store.watch(
      () => {
        return this.loaded
      },
      (newValue) => {
        if(newValue===true){
          this.loading = false
        }else{
          this.loading = true
        }
      },
      {
        deep: true
      }
    )

      this.$store.watch(
      () => {
        return this.loadedPercent
      },
      (newValue) => {
        if(newValue===1){
      this.$store.commit('markets/setLoadedPercent', false)

        }
      },
      {
        deep: true
      }
    )

  },
  computed: {
  ...mapGetters({
    loadedIterationCount: 'markets/loadedIterationCount',
    loaded: 'markets/loaded',
    loadedPercent: 'markets/loadedPercent',
    loadedMessage: 'markets/loadedMessage',
    }),
    loadedIterationCount_(){return this.loadedIterationCount},
    loaded_(){return this.loaded},
    loadedPercent_(){return this.loadedPercent},
    loadedMessage_(){return this.loadedMessage},
  },
  watch:{
    loaded_(newValue){
      this.loading=!newValue;
      let iterationCount = Number(this.loadedIterationCount)+1
      this.$store.commit('markets/updateLoadedIterationCount', iterationCount)
    },
    loadedPercent_(newValue){if(newValue===1){this.$store.commit('markets/setLoadedPercent', false)}else{
      this.$store.commit('markets/setLoadedMessage', false)
    }},
    loadedMessage_(newValue){
      this.message=newValue
    }
  },
  mounted() {
  }
}
</script>

<style>
#c1 {
  fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -224.671);
  animation: 1.5s c1 linear forwards infinite;
  animation-delay: 0s;
}

@keyframes c1 {
  0% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -224.671)
  }

  33% {fill:var(--good);
    transform: matrix(1, 0, 0, 1, -104.619, -602.576)
  }

  66% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -224.671)
  }

  100% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -224.671)
  }
}

#b1 {
  fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493);
  animation: 1.5s b1 linear forwards infinite;
  animation-delay: 0.5s;
}

@keyframes b1 {
  0% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }

  33% {fill:var(--good);
    transform: matrix(1, 0, 0, 1, 218.628, -36.0995)
  }

  66% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }

  100% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }
}

#a1 {
  fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493);
  animation: 1.5s a1 linear forwards infinite;
  animation-delay: 1s;
}

@keyframes a1 {
  0% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }

  33% {fill:var(--good);
    transform: matrix(1, 0, 0, 1, -427.866, -36.0995)
  }

  66% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }

  100% {fill:var(--primary);
    transform: matrix(1, 0, 0, 1, -103.095, -231.493)
  }
}

svg {
  transform-origin: 45% 50%;
    transform: rotate(30deg)

  /* animation: 4s r1 linear forwards infinite; */
}

@keyframes r1 {
  to {
    transform: rotate(360deg)
  }
}

#Loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--background);
  z-index: 99;
}

#Loading svg {
  max-width: 40vw;
  width: 150px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#Loading .message {
  max-width: 70vw;
  width: 300px;
  margin: auto;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 15px;
  text-align: center
}

#loaderBar{
  position: fixed;
  top: 0;
  left:0;
  width:100vw;
  height: 1rem;
  padding: 10px 0;
  background-color: var(--secondary);
  background-image: linear-gradient(90deg, var(--good), var(--primary));
  background-size: 100vw 3rem;
  background-repeat: no-repeat;
  transition:.2s ease
}
path{
  stroke: var(--primary);
  stroke-width:60px;
  /* antialiasing */
outline: 1px solid transparent;
-webkit-backface-visibility: hidden;
transform: translateZ(0);
will-change: transform;
-webkit-perspective: 1000;
}
</style>
