<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__inner">
      <div>
      <span>2021 | v0.1.1</span>
      </div>

      <div>
        <a class="footer__link">        
          <router-link :to="{name: 'SampleTerms'}">
          Sample ToC
          </router-link>
        </a>
        <a target="_blank" href="#" class="footer__link">Medium</a>
        <a target="_blank" href="#" class="footer__link">Github</a>
        <a target="_blank" href="#" class="footer__link">Discord</a>
        <a target="_blank" href="#" class="footer__link">Twitter</a>
      </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>
.footer__inner {
    padding:2px 10px;
    display: grid;
    grid-template: 1fr / 1fr auto;
    font-size: 12px;
    color: var(--primary);
}
.footer__inner a{
  margin-right:1rem
}
</style>