<template>
<header class="header">
  <div class="container">
    <div class="header__inner">
      <router-link :to="{name: 'exchange'}" class="header__logo">
        <img src="../../assets/image/logo.svg" alt="logo" class="logo">
        <span class="desktop-only"> decentralised<br>exchange</span>
      </router-link>
      <div v-if="loaded!==false && statusIndicator" id="statusIndicator" :class="statusIndicator" :title="'Data cache health is ' + statusText">{{statusText}}</div>
      <div v-if="loaded!==true" id="statusIndicator" class="loading" title="Loading">Synchronising</div>
      <div v-if="!statusIndicator"></div>
      <div class="market-manager-ui" v-if="loaded!==false && active" @click="toggleMerketsDropdown()">
        <a class="icon-btn fav-mark" @click.stop="toggleFavourite(false)">
          <i :class="!isFavourite(false)?'far fa-heart':'fas fa-heart'"></i>
        </a>
        <span class="token-logo"><img :src="getImage(active['logoURI'])"></span>

        <span class="token-name desktop-only">{{active['symbol']}}</span>
        <div class="stacked-details">
          <span class="token-detail">{{active['description']}}</span>
          <span class="token-detail">{{active['AMM']}}</span>
        </div>
        <div></div>

        <span class="token-price"><i :class="currencyComp==='ETH'?'fab fa-ethereum -o5' : 'fas fa-dollar-sign -o5'"></i> {{currencyComp==='ETH'?active['price'+currencyComp]
            : formatDollar(active['price'+currencyComp])}}</span>

        <span :class="'token-change '+active['txns24hChange']>=0?'good' : 'bad'"><i :class="active['txns24hChange']>=0?'fas fa-arrow-up -o5' : 'fas fa-arrow-down -o5'"></i> {{formatPercent(active['txns24hChange'])}} <i class="fas fa-percentage -o5"></i></span>

        <a class="btn" title="Search available tokens" @click.stop="toggleTokenSearch()"><i class="fas fa-search"></i></a>

        <i :class="marketDropdownvisible?'fas fa-chevron-up':'fas fa-chevron-down -o5'"></i>


          
        <div class="market-dropdown" v-if="marketDropdownvisible && markets.length>0">
          <div class="market-dropdown-inner-parent">
            <div class="token-search-input" @click.stop="focusSearch()">
              <input placeholder="Search available tokens" type="text" @click.stop="doSearch()" @input.stop="doSearch()" @focus.stop="doSearch()" v-model="searchTerm">
              <a class="btn" style="padding-right:10px" @click.stop="clearSearch()" v-if="searchTerm && searchTerm.length > 0" title="Clear Search"><i class="fas fa-times"></i></a>
            </div>
            <div class="market-dropdown-child" @click.stop="toggleMerketsDropdown()">
              <div v-for="(i, itemObjKey) in markets" v-bind:key="itemObjKey">
                <div class="row" v-if="active.address!== i.address && i.disabled!=='true' && (!searchFilter || nativeCheckSearchFilter(i.address))" @click.stop="changeCurrentMarket(itemObjKey)">
                  <a class="icon-btn fav-mark" @click.stop="toggleFavourite(itemObjKey)">
                    <i :class="!isFavourite(itemObjKey)?'far fa-heart':'fas fa-heart'"></i>
                  </a>
                  <span class="token-logo"><img :src="getImage(i['logoURI'])"></span>

                  <span class="token-name desktop-only">{{i['symbol']}}</span>

                  <div class="stacked-details">
                    <span class="token-detail" style="color:var(--text)">{{i['description']}}</span>
                    <span class="token-detail" style="color:var(--text)">{{i['AMM']}}</span>
                  </div>

                  <div></div>

                  <div style="position: relative;display:flex;flex-direction:row;flex-wrap:wrap;gap:10px">
                    <div class="token-liquidity" title="liquidity">
                      <i :class="currencyComp==='ETH'?'fab fa-ethereum -o5' : 'fas fa-dollar-sign -o5'"></i>
                      {{currencyComp==='ETH'?formatHumanise(i['liquidity'+currencyComp]):formatHumanise(i['liquidity'+currencyComp])}}
                    </div>

                    <div class="token-volume" title="volume">
                      <i :class="currencyComp==='ETH'?'fab fa-ethereum -o5' : 'fas fa-dollar-sign -o5'"></i> {{currencyComp==='ETH'?formatHumanise(i['volume'+currencyComp]) : formatHumanise(i['volume'+currencyComp])}}
                    </div>
                  </div>

                  <div>
                  </div>
                  <div style="position: relative;display:flex;flex-direction:row;flex-wrap:wrap;gap:10px">

                    <span class="token-price"><i :class="currencyComp==='ETH'?'fab fa-ethereum -o5' : 'fas fa-dollar-sign -o5'"></i> {{currencyComp==='ETH'?i['price'+currencyComp] : formatDollar(i['price'+currencyComp],true)}}</span>

                    <span :class="'token-change '+i['txns24hChange']>=0?'good' : 'bad'"><i :class="i['txns24hChange']>=0?'fas fa-arrow-up -o5' : 'fas fa-arrow-down -o5'"></i>{{formatPercent(i['txns24hChange'])}}
                      <i class="fas fa-percentage -o5"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="market-manager-ui loading" v-if="loaded!==true"></div>
      <nav class="header__nav">
        <div class="btn-switch-container">
          <a @click="setCurrency('USD')" :class="currencyComp==='USD'?'switch-btn selected':'switch-btn'">USD</a>
          <a @click="setCurrency('ETH')" :class="currencyComp==='ETH'?'switch-btn selected':'switch-btn'">Ether</a>
        </div>
        <quickSettingsModule />
        <walletModule />
      </nav>
    </div>
  </div>
</header>
</template>

<script>
// import getMarkets from '@/API/v1/markets'
// import pollWeb from '@/assets/libraries/web3.js'
import walletModule from '../WalletModule'
import quickSettingsModule from '../QuickSettingsModule'
import {
  mapGetters
} from 'vuex';

export default {
  name: 'Header',
  components: {
    walletModule,
    quickSettingsModule
  },
  data() {
    return {
      currentMarketIndex: false,
      currency: false,
      // favourites: false,
      marketData: false,
      currentMarket: false,
      marketsLoaded: false,
      marketDropdownvisible: false,
      statusInterval: false,
      statusIndicator: false,
      statusText: false,
      tempTimeValue: false,
      searchVisible: false,
      searchTerm:'',
      searchFilter: false,
    }
  },
  computed: {
    ...mapGetters({
      active: 'markets/active',
      markets: 'markets/markets',
      index: 'markets/index',
      currencyComp: 'markets/currency',
      loaded: 'markets/loaded',
      favourites: 'markets/favourites',
      lastUpdated: 'markets/lastUpdated',
      systemDataExpiry: 'markets/systemDataExpiry',
      systemDataCacheExpiryPercentageRemaining: 'markts/systemDataCacheExpiryPercentageRemaining',
    }),
    lastUpdated_() {
      return this.lastUpdated
    }
  },
  watch: {
    lastUpdated_(newValue) {
      this.tempTimeValue = newValue
      clearInterval(this.statusInterval)
      setInterval(() => {
        this.dataExpiryTimer();
        this.tempTimeValue--
      }, 1000)

    }
  },
  mounted() {
    // this.getMarkets();
    this.setup()
  },
  created() {

  },
  methods: {
    dataExpiryTimer() {
      let time = Date.now()
      let remainderPercentage = (time - this.tempTimeValue) / this.systemDataExpiry;

      if (remainderPercentage > .5) {
        this.statusIndicator = "descending";
        this.statusText = "healthy";
      }
      if (remainderPercentage > .85) {
        this.statusIndicator = "warn";
        this.statusText = "good";
      }
      if (remainderPercentage >= .99) {
        this.statusIndicator = "bad";
        this.statusText = "stale";
      }
      if (remainderPercentage <= .5) {
        this.statusIndicator = "good";
        this.statusText = "good";
      }
    },
    clicked() {
      this.clicked = !this.clicked
    },
    changeCurrentMarket(i) {
      this.$store.commit('markets/setCurrentMarketIndex', i)
      this.currentMarketIndex = this.index;
      this.currentMarket = this.active;
      this.marketDropdownvisible = false;
      this.clearSearch()
    },
    toggleMerketsDropdown() {
      this.marketDropdownvisible = !this.marketDropdownvisible
    },
    toggleTokenSearch(){
      this.searchVisible = !this.searchVisible;
      this.toggleMerketsDropdown();
    },
    focusSearch(){
      document.querySelector(".token-search-input").querySelector("input").focus();
    },
    doSearch(){
      if(!this.searchTerm || this.searchTerm&&this.searchTerm.length<0){
        this.clearSearch();
        return;
      }else{
        this.searchFilter=[];
        let tempFilterArray = [];
        let tempNameArray = [];
        let searchTerm = String(this.searchTerm.toUpperCase());
        for(const m of this.markets){
          console.log("searching for: " + searchTerm)
            let haystack = String(m.address) + " " + String(m.symbol) + " " + String(m.description);
            haystack = String(haystack.toUpperCase());
              if(String(haystack).indexOf(searchTerm)>-1){
                if(tempFilterArray.indexOf(m['address'])==-1){
                  tempFilterArray.push(m['address'])
                  tempNameArray.push(m.description)
                }
              }
        }
        this.searchFilter = tempFilterArray
      }
    },
    clearSearch(){
      this.searchTerm='';
      this.searchFilter=false;
    },
    nativeCheckSearchFilter(address){
      return (this.searchFilter.indexOf(address)==-1? false:true)
    },
    async setup() {
      this.currentMarket = this.active;
      this.currency = this.currencyComp;
      this.marketData = this.$store.getters.markets;
      this.marketsLoaded = this.$store.getters.loaded;
      this.currentMarketIndex = this.$store.getters.index;
      window.addEventListener("resize", setGlobalMenuHeightValueForCSS)

      function setGlobalMenuHeightValueForCSS() {
        let heightClearanceForHeader = document.querySelector("header").getBoundingClientRect().height;
        document.documentElement.style.setProperty('--heightClearanceForHeader', heightClearanceForHeader + 'px');
      }
      setGlobalMenuHeightValueForCSS()
    },
    getImage(u) {
      let v = u === false ? "token/default.png" : u
      return require('../../assets/image/' + v)
    },
    formatDollar(n, c) {
      let f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      if (c !== false) {
        return f.format(n).replace("$", '')
      }
      return f.format(n)
    },
    formatCurrency(n) {
      let f = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
      })
      return f.format(n)
    },
    formatInt(n) {
      let f = new Intl.NumberFormat('en-US', {})
      return f.format(n)
    },
    formatPercent(n) {
      let p = Math.abs(n);
      return (p * 100).toFixed(2)
    },
    isFavourite(i) {
      i = i === false ? this.index : i
      let c = this.markets[i].address;
      let favs = this.favourites;
      if (favs.indexOf(c) > -1) {
        return true;
      }
      return false;
    },
    toggleFavourite(i) {
      i = i === false ? this.index : i
      let c = this.markets[i].address;
      let favs = this.favourites;
      if (favs.indexOf(c) > -1) {
        favs = favs.filter(e => e !== c);
        // localStorage.setItem("favourites", favs)
        this.$store.commit("markets/setFavourites", favs);
      } else {
        favs.push(c)
        // localStorage.setItem("favourites", favs)
        this.$store.commit("markets/setFavourites", favs);
      }
      // this.favourites = favs
      //
      // 
    },
    setCurrency(c) {
      this.$store.commit("markets/setCurrencyGlobal", c);
      this.currency = this.currencyComp;

    },
    formatHumanise(n) {
      return Math.abs(Number(n)) >= 1.0e+9 ?
        (Math.abs(Number(n)) / 1.0e+9).toFixed(2) + " B" :
        Math.abs(Number(n)) >= 1.0e+6 ?
        (Math.abs(Number(n)) / 1.0e+6).toFixed(2) + " M" :
        Math.abs(Number(n)) >= 1.0e+3 ?
        (Math.abs(Number(n)) / 1.0e+3).toFixed(2) + " K" :
        Math.abs(Number(n)).toFixed(2);
    }
  }
}
</script>

<style>
#statusIndicator {
  margin: auto 1rem;
  font-size: 9px;
  text-transform: uppercase;
  box-shadow: 0 0 2px 0px;
  padding: 6px 10px;
  border-radius: 100px;
}

#statusIndicator::before {
  content: "";
  display: inline-block;
  height: .5rem;
  width: .5rem;
  box-shadow: inset 0 0 0 .5rem, 0 0 .5rem;
  border-radius: 100%;
  margin-right: 10px;
}
#statusIndicator.loading::before{
  transform-origin:center;
  box-shadow: 1px 1px 0 0;
  animation: spin 1s linear forwards infinite
}
@keyframes spin{
  to{transform:rotate(360deg)}
}
.market-manager-ui.loading{
  position: relative;
  overflow:hidden;
  z-index: 1;
  width: calc(100% - 60px);
}
.market-manager-ui.loading::after {
    content: "";
    width: 100%;
    height:100%;
    position: absolute;
  z-index: 1;
  box-shadow: inset 0 0 0 2px var(--background);
  border-radius: 5px;
}
.market-manager-ui.loading::before {
    content: "";
    width: 400%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(90deg, var(--warn), var(--secondary), var(--good));
    left:0;
    z-index: -1;
    animation: bswp0 2s ease alternate infinite;
}
@keyframes bswp0{
  100%{transform:translateX(-75%)}
}
</style>
