<template>
<div id="app">
  <Header />
  <div id="main">

  <router-view></router-view>
  </div>
  <Footer />
  <LoadingElement />
</div>
</template>

<script>
import Header from "@/components/global/Header";
import Footer from "@/components/global/Footer";
import LoadingElement from "@/components/global/LoadingElement";

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    LoadingElement,
  },
  data() {
    return {}
  },
  async created() {},
  methods: {
    clicked() {
      this.clicked = !this.clicked
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';
</style>
