<template>
<div id="buySell" class="container">
<div class="btn-switch-container disable-on-desktop">
  <a @click="setMode('buy')" :class="mode==='buy'?'buy-switch switch-btn selected':'buy-switch switch-btn'">Buying</a>
  <a @click="setMode('sell')" :class="mode==='sell'?'sell-switch switch-btn selected':'sell-switch switch-btn'">Selling</a>
</div>
  <div class="buy-sell-organiser">
    <section :class="mode==='buy'?'tab__content buying active':'tab__content buying'">
      <div class="field field--from">
        <div class="top-sect">
          <h4>From</h4>
        </div>
        <div class="selector-body">
          <div :class="focusedBuy?'combo-selector focused':'combo-selector'">
            <!--<div v-if="buycombo" class="combo-shadow"></div>-->
            <div :class="buycombo?'combo-inner expand':'combo-inner'">
              <a class="token token-trigger current-external" :title="external.symbol" @click="buyComboSelect()">
                <img class="token-logo" :src="getImage(external.logoURI)" :alt="external.symbol">
                <span class="token-name">{{external.symbol}}<i v-if="!buycombo">&nbsp;&nbsp;<i class="fas fa-caret-down"></i></i></span>
              </a>
              <a v-for="(i,key) in markets" :key="key">
                <span v-if="buycombo && i.disabled!=='true'&&i.symbol!==external.symbol&&i.symbol!==active.symbol&&filterExt(i.symbol)" class="token token-trigger" :title="i.symbol" @click="setActive(key)">
                  <img class="token-logo" :src="getImage(i.logoURI)" :alt="i.symbol">
                  <span class="token-name">{{i.symbol}}</span>
                </span>
              </a>
            </div>
            <div v-if="buycombo" class="combo-search"> 
            <i class="fas fa-search"></i>           
            <input class="input overlay small" type="text" step="any" title="search" placeholder="search" value="" v-model="filter">
            </div>
            <input @focus="focusX('Buy')" @blur="unfocusX('Buy')" @input="calculateExchange()" class="input overlay large" type="text" step="any" title="0" placeholder="0.0" value="" v-model="buyValue">
          </div>
        </div>
        <div class="bottom-sect presets">
          <div class="flex row">
            <a class="btn btn-small" disabled="">25%</a>
            <a class="btn btn-small" disabled="">50%</a>
            <a class="btn btn-small" disabled="">75%</a>
            <a class="btn btn-small" disabled="">100%</a>
          </div>
        </div>
      </div>
      <div class="field field--to">
        <div class="top-sect -inline-flex">
          <h4>To</h4>
            <div class="rates-detail">1&nbsp;<span class="sign">{{external.symbol}}</span>&nbsp;=&nbsp;<span class="curtail">{{external['priceETH']/active['priceETH']}}</span>&nbsp;<span class="sign">{{active.symbol}}</span>&nbsp;=&nbsp;<span><i class="fas fa-dollar-sign -o5"></i>{{formatDollar((external['priceETH']/active['priceETH'])*active['priceUSD'])}}</span>&nbsp;<span class="sign"></span></div>
          </div>
        <div class="selector-body">
          <div :class="focusedBuy?'combo-selector focused':'combo-selector'">
            <div class="combo-inner">
              <div class="token token-tile">
                <img class="token-logo" :src="getImage(active.logoURI)" :alt="active.symbol" :title="active.symbol">
                <span class="token-name" :title="active.symbol"><span class="caption">{{active.symbol}}</span>
                </span>
              </div>
            </div>
            <input class="input overlay large" readonly="" type="text" placeholder="0.0" title="0" :value="formatCurrency(buyValue2)">
          </div>
        </div>
      </div>
      <div class="proceed"><a class="btn-buy">Buy</a>
        <div class="proceed-message"></div>
      </div>
    </section>

    <section :class="mode==='sell'?'tab__content selling active':'tab__content selling'">
      <div class="field field--from">
        <div class="top-sect">
          <h4>From</h4>
        </div>
        <div class="selector-body">
          <div :class="focusedSell?'combo-selector focused':'combo-selector'">
            <div class="combo-inner">
              <div class="token token-tile">
                <img class="token-logo" :src="getImage(active.logoURI)" :alt="active.symbol" :title="active.symbol">
                <span class="token-name" :title="active.symbol"><span class="caption">{{active.symbol}}</span>
                </span>
              </div>
            </div>
            <input class="input overlay large" readonly="" type="text" placeholder="0.0" title="0" :value="formatCurrency(sellValue2)">
          </div>
        </div>
        <div class="bottom-sect presets">
          <div class="flex row">
            <a class="btn btn-small" disabled="">25%</a>
            <a class="btn btn-small" disabled="">50%</a>
            <a class="btn btn-small" disabled="">75%</a>
            <a class="btn btn-small" disabled="">100%</a>
          </div>
        </div>
      </div>
      <div class="field field--to">
        <div class="top-sect -inline-flex">
          <h4>To</h4>
            <div class="rates-detail">1&nbsp;<span class="sign">{{external.symbol}}</span>&nbsp;=&nbsp;<span class="curtail">{{external['priceETH']/active['priceETH']}}</span>&nbsp;<span class="sign">{{active.symbol}}</span>&nbsp;=&nbsp;<span><i class="fas fa-dollar-sign -o5"></i>{{formatDollar((external['priceETH']/active['priceETH'])*active['priceUSD'])}}</span>&nbsp;<span class="sign"></span></div>
          </div>
        <div class="selector-body">
          <div :class="focusedSell?'combo-selector focused':'combo-selector'">
            <div :class="sellcombo?'combo-inner expand':'combo-inner'">
              <a class="token token-trigger current-external" :title="external.symbol" @click="sellComboSelect()">
                <img class="token-logo" :src="getImage(external.logoURI)" :alt="external.symbol">
                <span class="token-name">{{external.symbol}}<i v-if="!sellcombo">&nbsp;&nbsp;<i class="fas fa-caret-down"></i></i></span>
              </a>
              <a v-for="(i,key) in markets" :key="key">
              <span v-if="sellcombo && i.disabled!=='true'&&i.symbol!==external.symbol&&i.symbol!==active.symbol&&filterExt(i.symbol)" class="token token-trigger" :title="i.symbol" @click="setActive(key)">
                <img class="token-logo" :src="getImage(i.logoURI)" :alt="i.symbol">
                <span class="token-name">{{i.symbol}}</span>
              </span>
              </a>
            </div>
            <div v-if="sellcombo" class="combo-search"> 
            <i class="fas fa-search"></i>           
            <input class="input overlay small" type="text" step="any" title="search" placeholder="search" value="" v-model="filter">
            </div>
            <input @focus="focusX('Sell')" @blur="unfocusX('Sell')" @input="calculateExchange()" class="input overlay large" type="text" step="any" title="0" placeholder="0.0" value="" v-model="sellValue">
          </div>
        </div>
      </div>
      <div class="proceed"><a class="btn-sell">sell</a>
        <div class="proceed-message"></div>
      </div>
    </section>
  </div>
</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
export default {
  name: 'BuySell',
  data() {
    return {
      mode: false,
      focusedBuy:false,
      focusedSell:false,
      buycombo:false,
      sellcombo:false,
      buyValue: 0,
      buyValue2: 0,
      sellValue: 0,
      sellValue2: 0,
      filter:"",
      buyComboMarkets: false
    }
  },
  computed: {
    ...mapGetters({
      active: 'markets/active',
      markets: 'markets/markets',
      currency: 'markets/currency',
      symbol: 'markets/chartSymbol',
      exchange: 'markets/activeAMM',
      external:'markets/external',
    }),
    external_(){return this.external},
    markets_(){return this.markets},
    
  },
  watch:{
    external_(){this.makeBuyComboMarkets()},
    markets_(){this.makeBuyComboMarkets()},
  },
  mounted() {},
  methods: {    
    async makeBuyComboMarkets(){
       let array = []
       let count = 0
       for (const m of this.markets){
        if(m.disabled!=='true'&&m.symbol!==this.external.symbol&&m.symbol!==this.active.symbol&&this.filterExt(m.symbol))
        {array.push({object:m, key:count})}
        count++
      }
      this.buyComboMarkets = array
    },
    filterExt(s){
      const f = this.filter;
      if(f.length==0){return true}
      if(!f){return true}
      if((s.toUpperCase()).indexOf((f.toUpperCase()))>-1){return true}
      return false
    },
    setActive(k){
        this.$store.commit("markets/setExternal", this.markets[k]);
        this.buycombo=false;this.sellcombo=false;this.buyValue="0.0";this.filter="";
    },
    buyComboSelect(){this.buycombo=!this.buycombo},
    sellComboSelect(){this.sellcombo=!this.sellcombo},
    calculateExchange(){
      const v = this.buyValue
      this.$store.dispatch('markets/getPriceEth')
      this.buyValue2 = ((this.external.priceETH/this.active.priceETH) * 1) * v
    },
    setMode(v){this.mode=v},
    focusX(a){this['focused'+a]=true},
    unfocusX(a){this['focused'+a]=false},
    getImage(u) {
      let v = u===false? "token/default.png" : u
      try{return require('../assets/image/' + v)}
      catch(error){
        return false
      }
    },
    formatDollar(n, c) {
      let f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      if (c !== false) {
        return f.format(n).replace("$", '')
      }
      return f.format(n)
    },
    formatCurrency(n) {
      let f = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
      })
      return f.format(n)
    },
  },
  created() {
    this.mode='buy';
    }
}
</script>

<style lang="scss">
#buySell {
.token-tile{background:var(--dark);}
.buy-switch:not(.selected):hover {background:transparent;color:var(--good);box-shadow:0 0 0 1px}
.buy-switch.selected {background:var(--good);color:var(--text)}
.sell-switch:not(.selected):hover {background:transparent;color:var(--warn);box-shadow:0 0 0 1px}
.sell-switch.selected {background:var(--warn);color:var(--text)}
  .btn-switch-container {
    background: var(--background);
    display: grid;
    grid-template: 1fr/1fr 1fr;
    text-align: center;
}
.tab__content.buying,.tab__content.selling,.tab__content:not(.active){
    @media screen and (max-width: 1120px){
      position:absolute;
      opacity:0;
      pointer-events:none;
      transform:translateY(20px);
  }
}

.tab__content.buying.active,.tab__content.selling.active{
    @media screen and (max-width: 1120px){
      transition:.2s ease;
      position:relative;
      opacity:1;
      pointer-events:all;
      transform:translateY(0px);
  }
}
.flex.row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
  @media screen and (max-width: 1120px){
    gap:10px;
  }
}

.btn-small {
    font-size: 12px;
    color: var(--z4);
    background: var(--z1);
    padding: 2px 8px;
  @media screen and (max-width: 1120px){
    padding: 2px 4px;
  }
}
.combo-selector {
    display: grid;
    grid-template: 1fr/auto 1fr;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 8px;
    transition:.2s ease;
    position: relative;
    z-index:3;
}
.combo-inner {
display: flex;
flex-direction: column;
padding: 10px;
position: absolute;
width: -webkit-max-content;
width: -moz-max-content;
width: max-content;
max-width: 100%;
overflow-y: scroll;
overflow-x: hidden;
border-radius: 8px 8px;
max-height: 150px;
z-index: 2;
align-items: flex-start;
bottom: 0;
    }
.combo-inner > a:not(:last-of-type) > span{
  margin-left: 10px;
}
.combo-inner.expand{
    background: var(--z3);
}
.combo-shadow {
  z-index:3;
  position: absolute;
  height:100%;
  width:100%;
  right:0;
  top:0;
  display:block;
  box-shadow: inset -40px 0 40px -40px #000;
  pointer-events:none;
  border-radius: 0 8px 8px 0;
}
input.overlay {
    background: transparent;
    border: 0;
    color: var(--text);
    border-radius:4px;
    width:100%;
}
input.large{
    font-size: 1.1rem;
    padding: 8px;
    text-align: right;
    grid-column: 2/3;
}
.combo-selector.focused {
    background: var(--dark);
}
.token{
    display: grid;
    grid-template: 1fr / auto auto;
    gap: 5px;
    align-items: center;
    padding: 5px 8px;
    border-radius: 4px;
}
.token-trigger.current-external {
    background: var(--z2);
}
.token-trigger:hover {
    background: var(--z1);
    box-shadow: 0 5px 15px -5px #000;
}
.token-trigger:active {
    box-shadow: 0 10px 25px -10px #000;
}
.tab__content {
    padding: 15px;
}
.top-sect{margin-bottom:5px;
&.-inline-flex{
  display: flex;
  flex-direction: row;
  
}}
.rates-detail {
    font-size: 13px;
    text-align: center;
    opacity: 1;
    font-family: 'IBM Plex Mono';
    font-weight: 100;
    color: var(--primary);
    display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
}
.btn-buy, .btn-sell {
    font-weight: 800;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    border-radius: 4px;
    padding: 5px 8px;
    max-width: 300px;
    margin: 8px auto;
    position: relative;
    display: block;
    z-index: 2;
    transition:.2s ease;
}
.btn-buy {
    background: var(--good);
}
.btn-sell {
    background: var(--warn);
}
.btn-buy:hover::before, .btn-sell:hover::before {
    content: "";
    height: 100%;
    position: absolute;
    display: block;
    width: 100%;
    background: var(--dark);
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
    opacity: .2;
}
.combo-search {
    position: absolute;
    bottom: 0;
    margin-bottom: calc(-1rem - 20px);
    width: 100%;
    padding: 10px;
    background: var(--dark);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 20px 30px -10px #000;
    display: grid;
grid-template: 1fr/auto 1fr;
column-gap: 10px;
align-items: center;
}
.buy-sell-organiser{
  display:flex;
  justify-content: space-evenly;
  position: relative;
  height: 100%;
  @media screen and (max-width: 1120px){
    flex-direction:column;
  }
  .field {
    display: grid;
    gap: 20px;
  }
}
.btn-switch-container.disable-on-desktop > a {
  @media screen and (min-width: 1120px){
    background: none !important;
    box-shadow: none !important;
    color: var(--z5) !important;
  }
}
}

.tab__content{
  box-shadow: 0 0 0 1px var(--z2);
  padding: 0 15px 15px 15px;
  margin: 10px;
}
.tab__content, #buySell {
  margin:10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
