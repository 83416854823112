/* eslint-disable */
export default {

  description: state => {
    if (!state.currentMarket) {
      return false
    }
    let s = state.currentMarket['description']
    return s
  },
  chartSymbol: state => {
    if (!state.currentMarket) {
      return false
    }
    let s = state.currentMarket['sampleChartID']
    return s
  },
  activeSymbol: state => {
    if (!state.currentMarket) {
      return false
    }
    let s = String(state.currentMarket['AMM']).toUpperCase() + " " + state.currentMarket['symbol']
    return s
  },
  favourites: state => {
    let fav = state.favourites? state.favourites: []
    return fav
  },
  loadedIterationCount: state => {
    return state.loadedIterationCount
  },
  loaded: state => {
    return state.marketsLoaded
  },
  loadedPercent: state => {
    return state.loadedPercent
  },
  loadedMessage: state => {
    return state.loadedMessage
  },
  currency: state => {
    return state.currency
  },
  markets: state => {
    return state.marketData
  },
  active: state => {
    if (!state.currentMarket && state.marketData && state.currentMarketIndex) {
      return state.marketData[state.currentMarketIndex];
    }
      return state.currentMarket
  },
  external: state => {
    if (state.external && state.marketData) {
      return state.external;
    }
      return {symbol:"ETH", logoURI:"eth.png", priceUSD:state.eth, priceETH:1}
  },
  activeUSD: state => {
    let c = state.currentMarket ? state.currentMarket.liquidityUSD : 0;
    let f = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })
    return f.format(c)
  },
  activeLiquidityChange24h: state => {
    let c = state.currentMarket.liquidityChange24h;
    let p = Math.abs(c);
    return (p * 100).toFixed(2)
  },
  swaps: state => {
    let a = []
    let tx = state.transactions.swaps;
    let s = state.currentMarket.symbol;
    // console.log(tx, s)
    if (tx) {
      for (const t of tx) {
        if (t.pair.token0.symbol === s || t.pair.token1.symbol === s) {
          a.push(t)
        }
      }
    }
    return a
  },
  liquidity: state => {
    let a = {mints:[], burns:[]}
    let s = state.currentMarket.symbol;

    let mints = state.transactions.mints;
    if (mints) {
      for (const t of mints) {
        if (t.pair.token0.symbol === s || t.pair.token1.symbol === s) {
          a.mints.push(t)
        }
      }
    }

    let burns = state.transactions.burns;
    if (burns) {
      for (const t of burns) {
        if (t.pair.token0.symbol === s || t.pair.token1.symbol === s) {
          a.burns.push(t)
        }
      }
    }
    return a
  },
  volumeETH: state => {
    let c = state.currentMarket.volumeETH;
    return c
  },
  activeVolumeUSD: state => {
    let c = state.currentMarket.volumeUSD;
    let f = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    })
    return f.format(c)
  },
  activeVolumeChange24h: state => {
    let c = state.currentMarket.volumeChange24h;
    let p = Math.abs(c);
    return (p * 100).toFixed(2)
  },
  index: state => {
    return state.currentMarketIndex
  },
  eth: state => {
    return state.eth ? state.eth : 1
  },
  lastUpdated: state => {
    return state.updated
  },
  systemDataExpiry: state => {
    return state.DATA_CACHE_EXPIRY_MS
  },
  systemDataCacheExpiryTimeRemaining: state => {
    return (state.updated + state.DATA_CACHE_EXPIRY_MS) - Date.now();
  },
  systemDataCacheExpiryPercentageRemaining: state => {
    return (Date.now() - state.updated)/state.DATA_CACHE_EXPIRY_MS;
  },
  autoCacheRefreshGlobalSetting: state=> {
    return state.autoDataCacheRefresh;
  },
  getUIEventListener: state => {
    return state.UIEventListener
  }
}