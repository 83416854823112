<template>
<div id="explorerPage">
<Volume />
<!-- <TradingVolume /> -->

</div>
</template>

<script>

import Volume from "@/components/VolumeMain";
// import TradingVolume from "@/components/TradingVolume";

import {
  mapGetters
} from 'vuex';

export default {
  name: 'VolumeMain',
  components: {
    Volume,
    // TradingVolume,
  },
data() {
    return {
      active: false
    }
  },
  mounted(){        
    this.events()
  },
  methods:{
      events(){
          window.globalMarketsVuexStore = this
      },
  },
    computed: {
    ...mapGetters({
      autoCacheRefreshGlobalSetting: 'markets/autoCacheRefreshGlobalSetting',
      systemDataExpiry: 'markets/systemDataExpiry',
    }),
    autoCacheRefreshGlobalSetting_() {
      return this.autoCacheRefreshGlobalSetting
    }
  },
  watch: {
    autoCacheRefreshGlobalSetting_(newValue) {
         this.events()
      const timeout = this.systemDataExpiry;
    console.log("This is the timeout again: ", timeout)
      if(newValue){
        clearTimeout(window.dataCacheRefreshTimeout)
        window.dataCacheRefreshTimeout = setTimeout(function () {
        window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")
        }, timeout);
        window.addEventListener("focus", function(){window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")}
        );
      }else{
         window.removeEventListener("focus", function(){window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")}
        ); 
      }
    }
  },

}
</script>


<style lang="scss">

.sidebar-container {
    max-width:300px;
    height: calc(100vh - (65px + 1rem));
    margin: 0;
    box-shadow: 0 0 0 1px var(--z2);
    position: relative;
    z-index: 2;
    display: grid;
    grid-template: auto auto 1fr / 1fr;
    width: 30vw;
}
.list {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px
}

.list .row {
    position: relative;
    padding: 15px 10px;
    color: var(--z6);
    transition: .2s ease;
    border-radius: 10px;
    z-index: 1;
}

.list .row:hover {
    color: var(--text);
    background: var(--background);
}

.row.sb-r {
    display: grid;
    align-items: center;
    font-size: 11px;
    grid-template: 1fr / 1fr auto;
}

.partners .fa-caret-right {
    color: var(--good);
}

.partners .fa-caret-left {
    color: var(--primary);
}

.partners {
    display: grid;
    width: 100%;
    gap: 4px;
}

.partners > span{
    display: flex;
    gap: 4px;
}

.curtail{
max-width: 30%;
overflow: hidden;
text-overflow: ellipsis;
}

.sidebar-header {
    display: grid;
    align-items: center;
    padding: 5px;
    gap:5px
}
.sidebar-header > * {
padding:10px
}
h4{margin:0;
font-weight: 800;
font-size: 14px;
text-transform: uppercase;
color: var(--z4);}
.container.stowed {
    width: 0 !important;
    overflow: hidden;
}

.sidebar-header-parent {
    display: grid;
}

.partners:last-of-type > span {
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: end;
}

#buySell {
    grid-column: 2/3;
    grid-row: 2/3;
    background: var(--background);
}

#Volume {
    grid-column: 1/2;
    grid-row: 1/3;
}

#TradingVolume {
    grid-column: 3/4;
    grid-row: 1/3;
}

#mainGraph{
    grid-column: 2/3;
    grid-row: 1/2; 
}

</style>
