<template>
  <div id="mainGraph" class="container active-tab-target">
    <div class="tradingview-widget-container">

      <modularLoader v-if="frameLoaded === false" />

      <div id="mainChartParent">
        <!-- TradingView Widget BEGIN -->
        <div id="chartElement_TV_0001">
        </div>
        <!-- TradingView Widget END -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import modularLoader from '@/components/global/ModularLoader'
export default {
  name: 'MainGraph',
  data() {
    return {
      frameLoaded: false,
      scriptLoaded: false,
    }
  },
  components: { modularLoader, },
  computed: {
    ...mapGetters({
      symbol: 'markets/chartSymbol',
      exchange: 'markets/activeAMM',
      UIEventListener: 'markets/getUIEventListener',
    }),
    symbol_() {
      return this.symbol
    },
    scriptLoaded_() { return this.scriptLoaded },
    UIEventListener_() { return this.UIEventListener },
  },
  watch: {
    symbol_(newValue) {
      this.scriptLoaded && (this.tradingChart(newValue))
    },
    scriptLoaded_() {
      this.symbol && (this.tradingChart(this.symbol))
    },
    UIEventListener_() {
      window.mainGraphParent.tradingChart(window.mainGraphParent.symbol)
    }
  },
  mounted() {
    window.mainGraphParent = this
    const script = document.createElement('script');
    script.id = 'tradingview-widget-script';
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/tv.js';
    script.onload = function () { window.mainGraphParent.scriptLoaded = true; };
    document.getElementsByTagName('head')[0].appendChild(script);
    window.addEventListener("resize", function () {
      window.mainGraphParent.tradingChart(window.mainGraphParent.symbol)
    })
  },
  methods:
  {
    tradingChart(s) {
      s = s.indexOf(':') >= 0 ? s.split(':')[1] : s
      let t = this;
      t.frameLoaded = false;

      if (document.getElementById("chartElement_TV_0001") && window.mainGraphParent.scriptLoaded)
        document.getElementById("chartElement_TV_0001").innerHTML = "";
      // eslint-disable-next-line
      new TradingView.widget({
        "autosize": true,
        "symbol": s,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "toolbar_bg": "#fff",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "hide_side_toolbar": false,
        "allow_symbol_change": true,
        "container_id": "chartElement_TV_0001"
      });
      const frame = document.getElementById("chartElement_TV_0001").querySelector("iframe");
      frame.onload = function () {
        t.frameLoaded = true;
        frame.style.opacity = "1";
      };
    }
  }
}
</script>

<style lang="scss">
#mainGraph,
.tradingview-widget-container,
#chartElement_TV_0001 {
  height: 650px;
  max-height: calc(55vh - 60px);
  position: relative;
  transition: .4s ease;
}

iframe,
#widget-container {
  opacity: 0;
  border-color: transparent !important;
  transition: .4s ease;
}
</style>
