<template>
<div>
<div class="modal-backer" v-if="modalOpen" @click="toggleModalSettings()"></div>
  <a class="icon-btn" @click="toggleModalSettings()">
    <span>
      <i class="fas fa-cog"></i>
    </span>
  </a>

  <div v-if="modalOpen" class="modal modal-connect-options">
    <div class="modal-top">
      <h3>Settings</h3>
      <span @click="toggleModalSettings()" class="btn"><i class="fas fa-times"></i></span>
    </div>
    <div class="-option" @click="toggleAutoCacheExpiryTimeout()">
      <i class="fas fa-toggle-on -xl btn" v-if="derivedStatus"></i>
      <i class="fas fa-toggle-off -xl btn" v-if="!derivedStatus"></i>
      <span>Auto-refresh system cache</span>
    </div>

    <div class="-option" @click="reloadMarkets();toggleModalSettings()">
      <i class="fas fa-sync"></i>
      <span>Manually Reload Data<br><small>Performs a full fetch of the latest market data.</small></span>
    </div>

    <div class="-option bad" @click="clearCache()">
      <i class="fas fa-broom"></i>
      <span>Clear Local Storage<br><small>Resets the app data stored in your browser.</small></span>
    </div>
  </div>

</div>
</template>

<script>
// import store from '@/store'
import {
  mapGetters
} from 'vuex';

export default {
  name: 'quickSettingsModule',
  data() {
    return {
      modalOpen: false,
      derivedStatus: false,
    }
  },
  methods: {
    reloadMarkets(){
      window.globalMarketsVuexStore.$store.commit("markets/setUpdated", 0);
      window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")    
},
    toggleModalSettings() {
      this.modalOpen = !this.modalOpen
    },
    toggleAutoCacheExpiryTimeout(){
      this.$store.commit('markets/setAutoDataCacheRefresh', !this.autoCacheRefreshGlobalSetting);
    },
    clearCache(){
      localStorage.clear();
      window.location.reload(false);
    }
  },
  computed: {
    ...mapGetters({
      autoCacheRefreshGlobalSetting: 'markets/autoCacheRefreshGlobalSetting',
    }),
    autoCacheRefreshGlobalSetting_() {
      return this.autoCacheRefreshGlobalSetting
    }
  },
  watch: {
    autoCacheRefreshGlobalSetting_(newValue) {
      this.derivedStatus = newValue
    },
  },

}
</script>