<template>
  <div id="TradingVolume">
    <span v-if="stowed" class="btn" @click="stowed=!stowed" style="height: 3rem;width: 2rem;display: grid;text-align: center;align-content: center;"><i class="fas fa-chevron-left"></i></span>

    <div :class="stowed?'stowed container sidebar-container':'container sidebar-container'">
      <div style="grid-template:repeat(4, auto) / 1fr" class="sidebar-header-parent">
        <div style="grid-template:1fr / auto auto 1fr" class="sidebar-header">
          <span class="btn no-mobile" @click="stowed=!stowed"><i class="fas fa-chevron-right"></i></span>
          <router-link :to="{name: 'volume'}" class="btn no-mobile">
            <i class="fas fa-expand"></i>
          </router-link>
          <h4 style="text-align:right">24hr Volume</h4>
        </div>
        <div class="header-price" :title="currency=='USD'?'$' + activeVolumeUSD :'ETH '+volumeETH">
          {{currency=='USD'?'':'ETH '}} {{currency=='USD'? activeVolumeUSD:formatCurrency(volumeETH)}}
        </div>
        <span :class="activeVolumeChange24h>0?'header-token-change good' : 'header-token-change bad'">
          <i :class="activeVolumeChange24h>0?'fas fa-arrow-up -o5' : 'fas fa-arrow-down -o5'"></i>
          {{activeVolumeChange24h}}
          <i class="fas fa-percentage -o5"></i>
        </span>
      </div>
      <div id="TradingVolumeChart">
      </div>
      <div class="list">

        <a target="_blank" v-for="(s, itemObjKey) in swaps" :href="'https://etherscan.io/tx/'+s.id.split('-')[0]" :class="'row flex-data-row compact-text bi-o ' + s.__typename" :style="'animation-delay:'+itemObjKey*.2 +'s'" :key="'volume-'+itemObjKey" :title="s.amount0Out + s.pair.token0.symbol + ' ' + s.amount1Out + s.pair.token1.symbol">
          <span><i :class="s.amount0In>0?'fas fa-caret-left':'fas fa-caret-right'"></i>&nbsp;{{s.pair.token0.symbol}}/<span class="-o5">{{s.pair.token1.symbol}}</span></span>
          <span :class="currency=='USD'?'token-value -o5' : 'token-value curtail -o5'">{{currency=='USD'?formatDollar(s.amountUSD, false):'ETH '+(eth*s.amountUSD)}}</span>
          <span class="token-time">{{elapsed(s.timestamp)}}</span>
        </a>


      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'TradingVolume',
  data() {
    return {
      stowed: false,
    }
  },
  computed: {
    ...mapGetters({
      currentMarket: 'markets/active',
      activeVolumeUSD: 'markets/activeVolumeUSD',
      volumeETH: 'markets/volumeETH',
      activeVolumeChange24h: 'markets/activeVolumeChange24h',
      currency: 'markets/currency',
      loaded: 'markets/loaded',
      swaps: 'markets/swaps',
    eth: 'markets/eth',
    }),
    currentMarket_(){return this.currentMarket},
    stowed_(){return this.stowed},
},
  watch:{
    currentMarket_(){this.createVolumeChart()},
    stowed_(){
      const time = Date.now()
      this.$store.commit("markets/triggerUIEventListener", time);
    }
  },
  methods: {
    formatCurrency(n) {
      let f = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
      })
      return f.format(n)
    },
    formatDollar(n, c) {
      let f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      if (c !== false) {
        return f.format(n).replace("$", '')
      }
      return f.format(n)
    },
    elapsed(previous) {
      var current = (new Date()).getTime();

      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - (previous * 1000);

      if (elapsed < msPerMinute) {
        return '~ ' + Math.round(elapsed / 1000) + ' s';
      } else if (elapsed < msPerHour) {
        return '~ ' + Math.round(elapsed / msPerMinute) + ' min';
      } else if (elapsed < msPerDay) {
        return '~ ' + Math.round(elapsed / msPerHour) + ' hr';
      } else if (elapsed < msPerMonth) {
        return '~ ' + Math.round(elapsed / msPerDay) + ' day';
      } else if (elapsed < msPerYear) {
        return '~ ' + Math.round(elapsed / msPerMonth) + ' mth';
      } else {
        return '~ ' + Math.round(elapsed / msPerYear) + ' yr';
      }
    },
    createVolumeChart() {
      let rec = [];
      let milli = 0;
      for (const s of this.swaps) {
        let d = (s.timestamp + milli) * 1000
        rec.push({
          time: d,
          value: Math.ceil(s.amountUSD)
        })
        milli++
      }

      rec = rec.sort(function (a, b) {
        return a.time - b.time;
      });

    }
  },
  async mounted() {

    let t = this;

    this.createVolumeChart();
    window.addEventListener("resize", function () {
      t.createVolumeChart()
    })
  }
}
</script>

<style lang="scss">
#TradingVolumeChart{
  max-width: 300px;
  width:30vw;
}
#TradingVolume
.header-price{
    background-image: linear-gradient(90deg,var(--primary),var(--z5));
}

</style>
