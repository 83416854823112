<template>
<div id="LiquidityMain">

    <div style="grid-template:repeat(4, auto) / 1fr" class="sidebar-header-parent">
      <div style="grid-template:1fr / 1fr auto auto" class="sidebar-header">
        <h4>Pool Liquidity</h4>
        <router-link :to="{name: 'exchange'}" class="btn">
          <i class="fas fa-arrow-left"></i>
        </router-link>
        
      </div>
      <div class="header-price" :title="currency=='USD'?activeUSD:'ETH'+currentMarket.liquidityETH">
      {{currency=='USD'?'':'ETH '}} {{currency=='USD'?activeUSD:currentMarket.liquidityETH}}</div>
      <span :class="activeLiquidityChange24h>0?'header-token-change good' : 'header-token-change bad'">
        <i :class="activeLiquidityChange24h>0?'fas fa-arrow-up -o5' : 'fas fa-arrow-down -o5'"></i>
        {{activeLiquidityChange24h}}
        <i class="fas fa-percentage -o5"></i>
      </span>
    </div>
    <div id="LiquidityVolumeChart">
    </div>
          <div class="list" v-if="combined.length>0">
          <div class="row header tableau -xtr">
            <span>Activity</span>
            <span>Token Value</span>
            <span>Token, A</span>
            <span>Token, B</span>
            <span>Account</span>
            <span>Time ago</span>
          </div>
      <a class="row bi-o tableau" target="_blank" v-for="(s, itemObjKey) in combined" :href="'https://etherscan.io/tx/'+s.id.split('-')[0]" :style="'animation-delay:'+itemObjKey*.2 +'s'" :key="itemObjKey">
          <span>
            <b v-if="s.__typename==='Mint'" style="color: var(--good);"><i class="far fa-gem -o5"></i>&nbsp;MINT</b>
            <b v-if="s.__typename==='Burn'" style="color: var(--warn);"><i class="fas fa-fire-alt -o5"></i>&nbsp;BURN</b>
          </span>
          <span class="token-value">{{currency=='USD'?formatDollar(s.amountUSD, false):'ETH '+(eth*s.amountUSD)}}</span>
          <span><span class="curtail">{{s.amount0}}</span><br><small class="-o5 pri">{{s.pair.token0.symbol}}</small></span>
          <span><span class="curtail">{{s.amount1}}</span><br><small class="-o5 pri">{{s.pair.token1.symbol}}</small></span>
          <span class="curtail pri">{{s.sender}}</span>
          <span>{{elapsed(s.timestamp)}}</span>
        </a>
      </div>
</div>
</template>

<script>
import { createChart } from 'lightweight-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'Liquidity',
  data() {
    return {
      combined:false,
    }
  },
    computed: {
  ...mapGetters({
    currentMarket: 'markets/active',
    activeUSD: 'markets/activeUSD',
    currency: 'markets/currency',
    activeLiquidityChange24h: 'markets/activeLiquidityChange24h',
    loaded: 'markets/loaded',
    liquidity: 'markets/liquidity',
    eth: 'markets/eth',
    }),
    currentMarket_(){return this.currentMarket}
},
  watch:{
    currentMarket_(){this.createLiquidityChart()}
  },
  methods: {
    formatCurrency(n) {
      let f = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
      })
      return f.format(n)
    },
    formatDollar(n, c) {
      let f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      if (c !== false) {
        return f.format(n).replace("$", '')
      }
      return f.format(n)
    },
    elapsed(previous) {
      var current = (new Date()).getTime();

      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - (previous * 1000);

      if (elapsed < msPerMinute) {
        return '~ ' + Math.round(elapsed / 1000) + ' s';
      } else if (elapsed < msPerHour) {
        return '~ ' + Math.round(elapsed / msPerMinute) + ' min';
      } else if (elapsed < msPerDay) {
        return '~ ' + Math.round(elapsed / msPerHour) + ' hr';
      } else if (elapsed < msPerMonth) {
        return '~ ' + Math.round(elapsed / msPerDay) + ' day';
      } else if (elapsed < msPerYear) {
        return '~ ' + Math.round(elapsed / msPerMonth) + ' mth';
      } else {
        return '~ ' + Math.round(elapsed / msPerYear) + ' yr';
      }
    },
    createLiquidityChart() {
      this.combined = [];
      let mints = [];
      let milli = 0;
      for (const s of this.liquidity.mints) {
        let d = (s.timestamp + milli) * 1000
        mints.push({
          time: d,
          value: Math.ceil(s.amountUSD)
        })
        this.combined.push(s)
        milli++
      }

      mints.sort(function (a, b) {
        return a.time - b.time;
      });

      let burns = [];
      let milli2 = 0;
      for (const s of this.liquidity.burns) {
        let d = (s.timestamp + milli2) * 1000
        burns.push({
          time: d,
          value: Math.ceil(s.amountUSD)
        })
        this.combined.push(s)
        milli2++
      }

      burns.sort(function (a, b) {
        return a.time - b.time;
      });

      this.combined.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      var chartElement = document.createElement('div');

      var chart = createChart(chartElement, {
        width: window.outerWidth<=500?window.outerWidth:document.getElementById("LiquidityVolumeChart").getBoundingClientRect().width,
        height: 150,
        layout: {
          backgroundColor: '#191d2b',
          textColor: '#26a69a',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,

          },
        },
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: true,
        },
        timeScale: {
          visible: false,
        },
        crosshair: {
          horzLine: {
            visible: false,
          },
        },

      });

      document.getElementById("LiquidityVolumeChart").innerHTML = "";
      let c = document.createElement('div');
      c.appendChild(chartElement);
      document.getElementById("LiquidityVolumeChart").append(c)
      var mintsSeries = chart.addAreaSeries({
        topColor: '#26a69a',
        bottomColor: '#191d2b',
        lineColor: '#26a69a',
        lineWidth: 2,
      });
      mintsSeries.setData(mints);

      var burnsSeries = chart.addAreaSeries({
        topColor: '#ef5350',
        bottomColor: '#191d2b',
        lineColor: '#ef5350',
        lineWidth: 2,
      });
      burnsSeries.setData(burns);
    }
  },
  async mounted() {

    let t = this;

    // function 

    t.createLiquidityChart(t);
    window.addEventListener("resize", function () {
      t.createLiquidityChart(t)
    })
  }
 
}
</script>

<style lang="scss">
#LiquidityMain{
.list {
  .row{
    &.Mint{
      padding: 10px 10px;
      margin-bottom:10px;
    }
    &.Burn{
      padding: 10px 10px;
      margin-bottom:10px;
    }
  }
}
.Mint::before, .Burn::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  opacity: .1;
  border-radius: 10px;
  z-index: -1;
}

.Mint::before{
  background: linear-gradient(to right, var(--good), transparent 30%);
  }

.Burn::before{
  background: linear-gradient(to right, var(--warn), transparent 30%);
}

.header-price {
  text-align: center;
  font-size: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px;
}

.header-token-change {
  font-size: 14px;
  text-align: center;
  padding: 2px 10px;
  display: block;
  // text-align: center;

}
}
</style>
