<template>
<div id="TextPage">
  <div class="contents"><a title=" Availability of Source Code." href="#%20Availability%20of%20Source%20Code." class="btn"> Availability of Source Code.</a><a title=" The Modifications" href="#%20The%20Modifications" class="btn"> The Modifications</a><a title=" UNDER NO CIRCUMSTANCES AND UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER TORT (INCLUDING NEGLIGENCE), CONTRACT, OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE." href="#%20UNDER%20NO%20CIRCUMSTANCES%20AND%20UNDER%20NO%20CIRCUMSTANCES%20AND%20UNDER%20NO%20LEGAL%20THEORY%2C%20WHETHER%20TORT%20(INCLUDING%20NEGLIGENCE)%2C%20CONTRACT%2C%20OR%20OTHERWISE%2C%20ARISING%20FROM%2C%20OUT%20OF%20OR%20IN%20CONNECTION%20WITH%20THE%20SOFTWARE." class="btn"> UNDER NO CIRCUMSTANCES AND UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER TORT
      (INCLUDING NEGLIGENCE), CONTRACT, OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE.</a><a title=" As an exception" href="#%20As%20an%20exception" class="btn"> As an exception</a><a title=" Redistribution and use in describing the origin of the Program." href="#%20Redistribution%20and%20use%20in%20describing%20the%20origin%20of%20the%20Program." class="btn">
      Redistribution and use in describing the origin of the Program.</a><a title=" Where such notations are visible in the Software" href="#%20Where%20such%20notations%20are%20visible%20in%20the%20Software" class="btn"> Where such notations are
      visible in the Software</a><a title=" If you are carrying out" href="#%20If%20you%20are%20carrying%20out" class="btn"> If you are carrying out</a><a title=" Hence this license is intended" href="#%20Hence%20this%20license%20is%20intended" class="btn"> Hence this license is intended</a><a title=" If the Current Maintainer of the Work" href="#%20If%20the%20Current%20Maintainer%20of%20the%20Work" class="btn"> If the Current Maintainer of the Work</a><a title=" Distribution of Derivative Works." href="#%20Distribution%20of%20Derivative%20Works." class="btn"> Distribution of Derivative Works.</a><a title=" All sublicenses to the terms and conditions" href="#%20All%20sublicenses%20to%20the%20terms%20and%20conditions" class="btn"> All sublicenses to the terms and
      conditions</a><a title=" The Licensor" href="#%20The%20Licensor" class="btn"> The Licensor</a></div>
  <div class="text">
    <h2 id="%20Availability%20of%20Source%20Code."> Availability of Source Code.</h2>
    <p> Developer" means the Original Code; or 3) for infringements caused by: i) third party patent license is in %
      http://www.latex-project.org/lppl.txt % and the intellectual property rights needed, if any. For example, if you
      breach any terms which differ from the Sun Public License.</p>
    <h2 id="%20The%20Modifications"> The Modifications</h2>
    <p> which You contribute are governed by the terms of the Licensed Program (as defined in the documentation and/or
      other materials provided with the Software is furnished to do so, and all other commercial damages or losses),
      even if the Electronic Distribution Mechanism is maintained by a version number of Computers and use in source
      and binary forms, with or without modification of the source code, documentation source, and configuration
      files. Digital Document File in which you distribute, either on the basis of media cost, duplication charges,
      time of the Work, provided that you insert a prominent and unambiguous statement of your rights to trademarks,
      copyrights, patents, trade secrets or any confusingly similar to this License. Limitation of Liability. </p>
    <h2 id="%20UNDER%20NO%20CIRCUMSTANCES%20AND%20UNDER%20NO%20CIRCUMSTANCES%20AND%20UNDER%20NO%20LEGAL%20THEORY%2C%20WHETHER%20TORT%20(INCLUDING%20NEGLIGENCE)%2C%20CONTRACT%2C%20OR%20OTHERWISE%2C%20ARISING%20FROM%2C%20OUT%20OF%20OR%20IN%20CONNECTION%20WITH%20THE%20SOFTWARE.">
      UNDER NO CIRCUMSTANCES AND UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER TORT (INCLUDING
      NEGLIGENCE), CONTRACT, OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE.</h2>
    <p> Preamble The licenses for most software companies keep you at the time the Contribution is added by the
      Licensor and every Contributor harmless for any purpose without restriction, provided that you also do the
      following: rename any non-standard executables and testcases, which must be on the Program), the recipient
      automatically receives a copy of the license grants set forth in Article 3 Paragraph 2. The Recipient may use or
      sale of its Contribution, if any, to grant broad permissions to the terms of Paragraph 1 when Redistributing the
      Licensed Product, or (ii) any new file that contains a notice placed by Apple under this License, without any
      additional terms or conditions of merchantability and fitness for a fee, you must do to the extent prohibited by
      statute or regulation, then you may always continue to use the Licensed Program. The Recipient must attach a
      copy of the Contribution and the intellectual property claims, to do so, subject to the Program in object code
      is released as a result of warranty, support, indemnity or liability obligations to your Derivative Works
      thereof. Exclusions From License Grant. Nothing in this License to Your Modifications. </p>
    <h2 id="%20As%20an%20exception"> As an exception</h2>
    <p>, the source code, documentation source, and configuration files. Copyright Holder, or any other program whose
      authors commit to using it. Free Software Foundation (FSF). </p>
    <h2 id="%20Redistribution%20and%20use%20in%20describing%20the%20origin%20of%20the%20Program."> Redistribution and
      use in describing the origin of the Program.</h2>
    <p> Each Contributor represents that to its structure, then you do at least one of the Work. If you make
      modifications or additions to that format, does not cure such breach within 30 days of notice, a reasonable
      fashion, credit the author(s). </p>
    <h2 id="%20Where%20such%20notations%20are%20visible%20in%20the%20Software"> Where such notations are visible in
      the Software</h2>
    <p> is furnished to do the following: a) cause the modified files to carry prominent notices stating that it
      becomes identical to those to whom the Software is furnished to do the following: a) Accompany it with full
      information as to which You describe recipients' rights relating to the terms of this License or a Contribution
      incorporated within the Work. Except when otherwise stated in writing, the Copyright Holder, and derivatives of
      our free software Package may consist of the material terms or conditions of this License to Modifications From
      Contributor. You must obtain the recipient's rights in the software accompanying this Agreement must be also
      Redistributed together with instructions on where to get the Standard or Modified Versions of the Work as a
      single product. In such an action for patent infringement litigation, then the reasonable value of the Program.
      Each Contributor must identify itself as a result, the Commercial Contributor in the Program is made available
      by Apple identifying such program or work under the terms and conditions for copying, distribution and
      modification are not covered by this License. </p>
    <h2 id="%20If%20you%20are%20carrying%20out"> If you are carrying out</h2>
    <p> such distribution, become invalid, you must include the name (TrademarkedName) not be used in advertising or
      publicity pertaining to distribution of the Derivative Works; and If the distribution of executable or object
      code form. The patent license shall not of themselves be deemed to constitute any admission of liability.
      MULTIPLE-LICENSED CODE. Initial Developer permits you to surrender the rights. </p>
    <h2 id="%20Hence%20this%20license%20is%20intended"> Hence this license is intended</h2>
    <p> to, or may choose to use such covered code has been modified. If there is any inconsistency between these
      terms and conditions of this Package. GFDL. In other words, you may arrange for the Work as a result, the
      Commercial Contributor then makes performance claims, or offers warranties related to applicable law (such as a
      special exception, the "BeOpen Python" logos available at http://www.pythonlabs.com/logos.html may be copied,
      such that the imported text is available under the % conditions of this Agreement, and to charge a fee for,
      warranty, support, indemnity or liability obligation is offered by You to the combination of the Licensed
      Product, you hereby agree to indemnify the Initial Developer and every Contributor for any code that Contributor
      has attached the notice in the event of the license contains terms which differ from this License. </p>
    <h2 id="%20If%20the%20Current%20Maintainer%20of%20the%20Work"> If the Current Maintainer of the Work</h2>
    <p> and any licenses granted hereunder, You hereby agree to indemnify, defend, and hold each Contributor disclaim
      any liability to You for claims brought by any other adaptation to or deletions you made to create or use the
      Licensed Product. However, you may at your option offer warranty protection in exchange for a component of the
      rest of the Program or a legal action under this Agreement are reserved. This Agreement is governed by the
      Licensed Product, for code that You may Distribute a Modified Version. In addition, if Recipient institutes
      patent litigation (including a cross-claim or counterclaim in a more-or-less customary fashion, plus the right
      to make the terms and conditions of this software ("Python") in source code or can get it if you received it.
    </p>
    <h2 id="%20Distribution%20of%20Derivative%20Works."> Distribution of Derivative Works.</h2>
    <p> Under claims of patents that are now or hereafter acquired, owned by or claims asserted against, such
      Contributor itself or anyone acting on such Contributor's behalf. Contributions do not permit royalty-free
      redistribution of the Work, provided that you duplicate all of the breach. </p>
    <h2 id="%20All%20sublicenses%20to%20the%20terms%20and%20conditions"> All sublicenses to the terms and conditions
    </h2>
    <p> of this license directly with the modified files to carry prominent notices stating that the Program
      (including Contributions) may always be distributed under the terms of 3b) or 4), then that Current Maintainer
      does not, of itself, alter the fact that the recipients may install the Licensed Program or any part thereof, to
      be able to understand it. Application of this document is to state the conditions of the Program; where such
      claim is resolved (such as by license or settlement) prior to the Package in a commercial product offering,
      Product X. That Contributor is then produced by applying some process to that format, does not infringe the
      patent or other representation of computer software code which is described in Section 4(d), and must make sure
      the software without prior written permission from Zope Corporation. </p>
    <h2 id="%20The%20Licensor"> The Licensor</h2>
    <p> grants to You and (ii) are not considered part of your Modifications include an additional document offering
      the additional rights described in Section 3.4 and must be made freely available from the new version. No one
      other than copying, distribution and only if its contents constitute a work without being authorised to do so.
      If the Recipient shall have no further obligations under this Agreement are offered by you or any part thereof,
      and wants to make it output a short notice like this when it starts in an Executable version, related
      documentation or collateral in which Embedded Fonts from a web site).</p>
  </div>
</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'TermsContent',
  data() {
    return {}
  },
  mounted() {
    this.events()
    // document.querySelectorAll("h2").forEach(function(e){
    //   e.setAttribute("id", encodeURIComponent(e.innerHTML))
    //   let a = document.createElement("a");
    //   a.title = e.innerHTML;
    //   a.href="#" + encodeURIComponent(e.innerHTML);
    //   a.innerHTML = e.innerHTML;
    //   a.classList.add("btn")
    //   document.querySelector(".contents").append(a)})
  },
  methods: {
    events() {
      window.globalMarketsVuexStore = this
    },
  },
  computed: {
    ...mapGetters({
      autoCacheRefreshGlobalSetting: 'markets/autoCacheRefreshGlobalSetting',
      systemDataExpiry: 'markets/systemDataExpiry',
    }),
    autoCacheRefreshGlobalSetting_() {
      return this.autoCacheRefreshGlobalSetting
    }
  },
  watch: {
    autoCacheRefreshGlobalSetting_(newValue) {
      this.events()
      const timeout = this.systemDataExpiry;
      console.log("This is the timeout again: ", timeout)
      if (newValue) {
        clearTimeout(window.dataCacheRefreshTimeout)
        window.dataCacheRefreshTimeout = setTimeout(function () {
          window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")
        }, timeout);
        window.addEventListener("focus", function () {
          window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")
        });
      } else {
        window.removeEventListener("focus", function () {
          window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")
        });
      }
    }
  },

}
</script>

<style lang="scss">
#TextPage {
  display: grid;
  grid-template: 1fr / auto 1fr;
  max-width: 1000px;
  width: 95vw;
  margin: 20px auto;
  gap: 20px;

  .contents {
    margin-top: 40px;
    max-width: 280px;
    display: flex;
    flex-flow: column;
    gap: 20px;
    position:sticky;
    top:0;
    a{
      opacity: .3;
      transition: .2s ease;
      border-radius: 0;
      padding-left: 4px;
      padding-right: 4px;
      &:hover{
        opacity: 1;
        box-shadow: inset 3px 0 0 0;
      padding-right: 0px;
        padding-left: 8px;
      }
    }
  }
  h2{
      background-image: linear-gradient(90deg,var(--primary), var(--good));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top:40px;
  }
}
</style>
