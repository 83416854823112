<template>
<div id="Liquidity">
  <span v-if="stowed" class="btn" @click="stowed=!stowed" style="height: 3rem;width: 2rem;display: grid;text-align: center;align-content: center;"><i class="fas fa-chevron-right"></i></span>

  <div :class="stowed?'stowed container sidebar-container':'container sidebar-container'">

    <div style="grid-template:repeat(4, auto) / 1fr" class="sidebar-header-parent">
      <div style="grid-template:1fr / 1fr auto auto" class="sidebar-header">
        <h4>Liquidity</h4>
        <router-link :to="{name: 'liquidity'}" class="btn no-mobile">
          <i class="fas fa-expand"></i>
        </router-link>
        <span class="btn no-mobile" @click="stowed=!stowed"><i class="fas fa-chevron-left"></i></span>
      </div>
      <div class="header-price" :title="currency=='USD'?activeUSD:'ETH'+currentMarket.liquidityETH">
      {{currency=='USD'?'':'ETH '}} {{currency=='USD'?activeUSD:currentMarket.liquidityETH}}</div>
      <span :class="activeLiquidityChange24h>0?'header-token-change good' : 'header-token-change bad'">
        <i :class="activeLiquidityChange24h>0?'fas fa-arrow-up -o5' : 'fas fa-arrow-down -o5'"></i>
        {{activeLiquidityChange24h}}
        <i class="fas fa-percentage -o5"></i>
      </span>
    </div>
    <div id="LiquidityVolumeChart">
    </div>
      <div class="list" v-if="combined.length>0" :title="combined.length">
        <a target="_blank" v-for="(s, key) in combined" :href="'https://etherscan.io/tx/'+s.id.split('-')[0]" :class="'row flex-data-row compact-text bi-o ' + s.__typename" :style="'animation-delay:'+key*.2 +'s; border-radius:0;'" :key="'liquidity-'+key" :title="s.amount0 + s.pair.token0.symbol + ' ' + s.amount1 + s.pair.token1.symbol">
          <span>{{s.pair.token0.symbol}}/<span class="-o5">{{s.pair.token1.symbol}}</span></span>
          <span :class="currency=='USD'?'token-value -o5' : 'token-value curtail -o5'">{{currency=='USD'?formatDollar(s.amountUSD, false):'ETH '+(eth*s.amountUSD)}}</span>
          <span class="token-time">{{elapsed(s.timestamp)}}</span>
        </a>
      </div>
  </div>
</div>
</template>

<script>
import { createChart } from 'lightweight-charts';
import { mapGetters } from 'vuex';

export default {
  name: 'Liquidity',
  data() {
    return {
      stowed: false,
      combined:false,
    }
  },
    computed: {
  ...mapGetters({
    currentMarket: 'markets/active',
    activeUSD: 'markets/activeUSD',
    currency: 'markets/currency',
    activeLiquidityChange24h: 'markets/activeLiquidityChange24h',
    loaded: 'markets/loaded',
    liquidity: 'markets/liquidity',
    eth: 'markets/eth',
    }),
    currentMarket_(){return this.currentMarket},
    stowed_(){return this.stowed},
},
  watch:{
    currentMarket_(newValue){this.createLiquidityChart(this, newValue)},
    stowed_(){
      const time = Date.now()
      this.$store.commit("markets/triggerUIEventListener", time);
    }
  },
  methods: {
    formatCurrency(n) {
      let f = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2
      })
      return f.format(n)
    },
    formatDollar(n, c) {
      let f = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      if (c !== false) {
        return f.format(n).replace("$", '')
      }
      return f.format(n)
    },
    elapsed(previous) {
      var current = (new Date()).getTime();

      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;

      var elapsed = current - (previous * 1000);

      if (elapsed < msPerMinute) {
        return '~ ' + Math.round(elapsed / 1000) + ' s';
      } else if (elapsed < msPerHour) {
        return '~ ' + Math.round(elapsed / msPerMinute) + ' min';
      } else if (elapsed < msPerDay) {
        return '~ ' + Math.round(elapsed / msPerHour) + ' hr';
      } else if (elapsed < msPerMonth) {
        return '~ ' + Math.round(elapsed / msPerDay) + ' day';
      } else if (elapsed < msPerYear) {
        return '~ ' + Math.round(elapsed / msPerMonth) + ' mth';
      } else {
        return '~ ' + Math.round(elapsed / msPerYear) + ' yr';
      }
    },
    createLiquidityChart(t) {
      t.combined = [];
      let mints = [];
      let milli = 0;
      for (const s of t.liquidity.mints) {
        let d = (s.timestamp + milli) * 1000
        mints.push({
          time: d,
          value: Math.ceil(s.amountUSD)
        })
        t.combined.push(s)
        milli++
      }

      mints.sort(function (a, b) {
        return a.time - b.time;
      });

      let burns = [];
      let milli2 = 0;
      for (const s of t.liquidity.burns) {
        let d = (s.timestamp + milli2) * 1000
        burns.push({
          time: d,
          value: Math.ceil(s.amountUSD)
        })
        t.combined.push(s)
        milli2++
      }

      burns.sort(function (a, b) {
        return a.time - b.time;
      });

      t.combined.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      var chartElement = document.createElement('div');

      var chart = createChart(chartElement, {
        width: window.outerWidth<=500?window.outerWidth:document.getElementById("LiquidityVolumeChart").getBoundingClientRect().width,
        height: 150,
        layout: {
          backgroundColor: '#191d2b',
          textColor: '#26a69a',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,

          },
        },
        rightPriceScale: {
          visible: false,
        },
        leftPriceScale: {
          visible: true,
        },
        timeScale: {
          visible: false,
        },
        crosshair: {
          horzLine: {
            visible: false,
          },
        },

      });

      document.getElementById("LiquidityVolumeChart").innerHTML = "";
      let c = document.createElement('div');
      c.appendChild(chartElement);
      document.getElementById("LiquidityVolumeChart").append(c)
      var mintsSeries = chart.addAreaSeries({
        topColor: '#26a69a',
        bottomColor: '#191d2b',
        lineColor: '#26a69a',
        lineWidth: 2,
      });
      mintsSeries.setData(mints);

      var burnsSeries = chart.addAreaSeries({
        topColor: '#ef5350',
        bottomColor: '#191d2b',
        lineColor: '#ef5350',
        lineWidth: 2,
      });
      burnsSeries.setData(burns);
    }
  },
  async mounted() {

    let t = this;

    // function 

    t.createLiquidityChart(t);
    window.addEventListener("resize", function () {
      t.createLiquidityChart(t)
    })
  }
 
}
</script>

<style lang="scss">
#LiquidityVolumeChart{
  max-width: 300px;
  width:30vw;
}
.list .row.Mint, .list .row.Burn {
  margin: 0px 10px 0px 10px;
}


.Mint{
  box-shadow: inset 2px 0 0 0 var(--good);
  }

.Burn{
    box-shadow: inset 2px 0 0 0 var(--warn);

}

.header-price {
  text-align: center;
  font-size: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px;
    background-image: linear-gradient(90deg,var(--good),var(--z5));
}

.header-token-change {
  font-size: 14px;
  text-align: center;
  padding: 2px 10px;
  display: block;
  // text-align: center;

}
</style>
