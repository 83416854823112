<template>
<div>
<div class="modal-backer" v-if="modalOpen" @click="toggleModal()"></div>
  <a class="icon-btn" @click="toggleModal()">
    <span v-if="!walletConnected">
      <i class="fas fa-wallet"></i>
      <span class="desktop-only">{{!walletConnected? walletText?walletText:' Connect': ' Good'}}</span>
    </span>
    <span v-if="walletConnected">
      <i class="far fa-user-circle"></i>
      <span class="desktop-only">{{this.shortAddress(ethersAddress)}}</span>
    </span>
  </a>

  <div v-if="!walletConnected && modalOpen" class="modal modal-connect-options">
    <div class="modal-top">
      <h3>Select Wallet Provider</h3>
      <span @click="toggleModal()" class="btn"><i class="fas fa-times"></i></span>
    </div>
    <div class="-option" @click="selectProviderMetaMask()">
      <img src="@/assets/image/metamask-logo.svg" alt="metamask logo">
      <span>MetaMask</span>
    </div>
  </div>

  <div v-if="walletConnected && modalOpen" class="modal modal-connect-options">
    <div class="modal-top">
      <h3 :title="this.ethersAddress">Identity: {{this.shortAddress(ethersAddress)}}</h3>
      <span @click="toggleModal()" class="btn"><i class="fas fa-times"></i></span>
    </div>
    <div class="-option" @click="disconnectWallet()">
      <i class="fas fa-unlink"></i>
      <span>Disconnect Wallet</span>
    </div>
  </div>

</div>
</template>

<script>
// import ethersActions from '@/store/ethers/actions.js';
import store from '@/store'
import {
  mapGetters
} from 'vuex';

export default {
  name: 'walletModule',
  data() {
    return {
      accounts: false,
      walletConnected: false,
      walletText: false,
      modalOpen: false,
    }
  },
  mounted() {},
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen
      // store.dispatch('ethers/connect')
    },
    selectProviderMetaMask() {
      this.modalOpen = false;
      store.dispatch('ethers/connect')
    },
    shortAddress(address) {
      return `${address.substring(0,4)}...${address.substring(address.length-5,address.length-1)}`
    },
    showWalletOptions() {
      this.toggleModal()
    },
    disconnectWallet() {
      store.dispatch('ethers/disconnect');
      this.modalOpen = false;
    },
  },
  computed: {
    ...mapGetters({
      ethersAddress: 'ethers/address',
      ethersConnected: 'ethers/connected',
    }),
    ethersConnected_() {
      return this.ethersConnected
    }
  },
  watch: {
    ethersConnected_(newValue) {
      this.walletConnected = newValue
    }
  },

}

</script>
