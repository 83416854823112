/* eslint-disable */
import marketsVuexStore from '@/store'
export default {
  setFavourites(state, payload) {
    localStorage.setItem("favourites", JSON.stringify(payload))
    state.favourites = payload
  },
  setMarketsCache(state, payload) {
    localStorage.setItem("marketsCache", JSON.stringify(payload))
    state.marketsCache = payload
  },
  setMarketData(state, payload) {
    state.marketData = payload
  },
  setCurrentMarket(state, payload) {
    state.currentMarket = payload
  },
  setExternal(state, payload) {
    state.external = payload
  },
  setCurrencyGlobal(state, payload) {
    state.currency = payload
  },
  setMarketsLoaded(state, payload) {
    state.marketsLoaded = payload
  },
  setCurrentMarketIndex(state, payload) {
    localStorage.setItem("lastMarketIndex", payload)
    state.currentMarketIndex = payload
    state.marketData&&(
      state.currentMarket = state.marketData[payload]
    )
  },
  updateLoadedIterationCount(state, payload) {
    state.loadedIterationCount = payload
  },
  updateMarketData(state, payload) {
    for (const [key, value] of Object.entries(payload)) {
    state.marketData[state.currentMarketIndex][key] = value
    }
    state.currentMarket = state.marketData[state.currentMarketIndex]
  },
  setEthPrice(state, payload) {
    state.eth = payload
  },
  setLoadedPercent(state, payload) {
    state.loadedPercent = payload
  },
  setLoadedMessage(state, payload) {
    state.loadedMessage = payload
  },
  setUpdated(state, payload) {
    state.updated = payload
    localStorage.setItem("updated", payload)
  },
  setTransactions(state, payload) {
    state.transactions = payload
  },
  setDataCacheExpiryMS(state, payload) {
    window.DATA_CACHE_EXPIRY_MS = payload;
    state.DATA_CACHE_EXPIRY_MS = payload
  },
  setAutoDataCacheRefresh(state, payload) {
    state.autoDataCacheRefresh = payload;
    localStorage.setItem("autoDataCacheRefresh", payload)
    if (payload !== 'false') {
      clearTimeout(window.dataCacheRefreshTimeout)
        window.dataCacheRefreshTimeout = setTimeout(function () {
        window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")
        }, state.systemDataExpiry);
        window.addEventListener("focus", function(){window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets")}
        );
    } else {
      marketsVuexStore.dispatch("markets/disableDataCacheRefresh");
    }
  },
  triggerUIEventListener(state, payload) {
    state.UIEventListener = payload
  }
}