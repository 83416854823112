/* eslint-disable */
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  marketData: false,
  currentMarket: false,
  eth: false,
  currency: 'USD',
  marketsLoaded: false,
  currentMarketIndex: false,
  favourites: false,
  loadedIterationCount: 0,
  loadedPercent: false,
  loadedMessage: false,
  updated: false,
  marketsCache: false,
  transactions: false,
  external: false,
  autoDataCacheRefresh: false,
  DATA_CACHE_EXPIRY_MS: false,
  UIEventListener:false,
})
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
