/* eslint-disable */
import {
  ApolloClient,
  InMemoryCache,
  gql
} from '@apollo/client';

const DATA_CACHE_EXPIRY_MS = 600000;
let queueCount = 0;
let completedCount = 0;
export default {
  async getPriceEth(context) {
    queueCount++
    const APIURL = "https://api.thegraph.com/subgraphs/name/sushiswap/exchange";
    const tokensQuery = `
      query ethPriceQuery {
        bundles(id: 1) {
          ...bundleFields
          __typename
        }
      }
      fragment bundleFields on Bundle {
        id
        ethPrice
        __typename
      }`
    const client = new ApolloClient({
      uri: APIURL,
      cache: new InMemoryCache()
    });
    client.query({
      query: gql(tokensQuery)
    })
      .then(function (data) {
        // console.log(data)

        let result = data.data.bundles[0].ethPrice
        context.commit('setEthPrice', result);
        completedCount++
        // console.log("Current ETH: ", result)
      })
      .catch(err => {
        console.log(err)
      })
  },
  async getTokenHistory(context) {
    queueCount++
    const APIURL = "https://api.thegraph.com/subgraphs/name/sushiswap/exchange";
    const pairAddresses = `
      [
        "0x110492b31c59716ac47337e616804e3e3adc0b4a",
        "0xdac17f958d2ee523a2206206994597c13d831ec7",
        "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
        "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "0x3883f5e181fccaf8410fa61e12b59bad963fb645",
        "0xd850942ef8811f2a866692a623011bde52a462c1",
        "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        "0x6b175474e89094c44da98b954eedeac495271d0f",
        "0x6e1A19F235bE7ED8E3369eF73b196C07257494DE",
        "0xe1be5d3f34e89de342ee97e6e90d405884da6c67",
        "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
        "0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
        "0x75231f58b43240c9718dd58b4967c5114342a86c",
        "0x39aa39c021dfbae8fac545936693ac917d5e7563",
        "0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5",
        "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
        "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",


        "0x110492b31c59716ac47337e616804e3e3adc0b4a","0x164fe0239d703379bddde3c80e4d4800a1cd452b","0x18d98d452072ac2eb7b74ce3db723374360539f1","0x2024324a99231509a3715172d4f4f4e751b38d4d","0x2a11ccfb96223bd35ec24b2aac046fcc85035f3d","0x2dbc7dd86c6cd87b525bd54ea73ebeebbc307f68","0x2df6bb12ba376a948cba22ae9742016010b40c5c","0x305a9a2f97bf1beaa479d27b2e0899acd7f26bf7","0x518d6ce2d7a689a591bf46433443c31615b206c5","0x69d4f0e72a9de650dd3fe1f185ed3bf1e9e7e3ed","0x7835cb043e8d53a5b361d489956d6c30808349da","0x7cd7d26d32f530acfb93458927bd426604f30d12","0x9266554f50a0a3999a9781eac95421517bc6cc84","0x9a13867048e01c663ce8ce2fe0cdae69ff9f35e3","0x9a62448d05bc4404402fc7f2f2cb16d32a503c5c","0xcd83cada168c389fb5221e2cc961e6a56163176a","0xceff51756c56ceffca006cd410b03ffc46dd3a58","0xd081f06f06eb774f642195e8dd4c393de07486a9","0xd227083b6feb1a40a975d74575311fb907fb960f","0xd715b4b579ed3e3b126620a308da2c5b6680d800","0xd975b774c50aa0aeacb7b546b86218c1d7362123","0xe848bc0bc764135d0ae462d9975dc13be0feae2a","0xf4259e13dcadafc4361d94898729212393ce380f","0xfa3e522202c0dcca92e74e706bf503fae8aa01cf"]
      `
    const tokensQuery = `
    query transactionsQuery {
  swaps(orderBy: timestamp, orderDirection: desc, where: {pair_in: ${pairAddresses}}) {
    id
    timestamp
    pair {
      token0 {
        symbol
        __typename
      }
      token1 {
        symbol
        __typename
      }
      __typename
    }
    sender
    amount0In
    amount0Out
    amount1In
    amount1Out
    amountUSD
    to
    __typename
  }
  mints(orderBy: timestamp, orderDirection: desc, where: {pair_in: ${pairAddresses}}) {
    id
    timestamp
    pair {
      token0 {
        symbol
        __typename
      }
      token1 {
        symbol
        __typename
      }
      __typename
    }
    sender
    amount0
    amount1
    amountUSD
    to
    __typename
  }
  burns(orderBy: timestamp, orderDirection: desc, where: {pair_in: ${pairAddresses}}) {
    id
    timestamp
    pair {
      token0 {
        symbol
        __typename
      }
      token1 {
        symbol
        __typename
      }
      __typename
    }
    sender
    amount0
    amount1
    amountUSD
    to
    __typename
  }
}
    `
    const client = new ApolloClient({
      uri: APIURL,
      cache: new InMemoryCache()
    });
    client.query({
      query: gql(tokensQuery)
    })
      .then(function (data) {
        // console.log(data.data)
        let result = data.data
        context.commit('setTransactions', result);
        completedCount++
      })
      .catch(err => {
        console.log(err)
      })
  },
  async getMarkets(context) {
    const callback = function (payload) {
      context.commit('setMarketData', payload);
      context.commit('setMarketsCache', payload);

      context.commit('setCurrentMarket', payload[context.state.currentMarketIndex]);
      context.commit('setMarketsLoaded', true);
    }
    const time = Date.now()
    if (context.state.marketsCache && context.state.marketsCache.length > 0 && context.state.updated && time - context.state.updated < DATA_CACHE_EXPIRY_MS) {
      callback(context.state.marketsCache)
    } else {
      const requestClient = require('request')
      const uri = 'https://api.flat18.co.uk/hosted/decentralised-exchange/markets.php?data'
      requestClient.get({
        uri,
        json: true
      },
        (error, response) => {
          if (error) console.error(error)
          else
            context.commit('setMarketsLoaded', false);
          let payload = response.body;
          for (const i of payload) {
            if (i.disabled !== "true") { queueCount++ }
          }
          //Operate on data
          async function timeMachineQueryGraphQL(e) {
            let a = e.address;
            let exc = e.AMM;
            let spN = false
            switch (exc) {
              case "sushiswap":
                spN = "SUSHISWAP"
                break;
              case "quickswap":
                spN = "QUICKSWAP"
                break;
              default:
                spN = "QUICKSWAP"
            }
            const APIURL = "https://api.thegraph.com/subgraphs/name/sushiswap/exchange";
            const tokensQuery = `
              query tokenTimeTravelQuery {
                token(id: "${a}", block: {number:12529095}) {
                  ...tokenFields
                  __typename
                }
              } 
      
              fragment tokenFields on Token {
                id
                symbol
                name
                decimals
                totalSupply
                volume
                volumeUSD
                untrackedVolumeUSD
                txCount
                liquidity
                derivedETH
                __typename
              }
              `
            const client = new ApolloClient({
              uri: APIURL,
              cache: new InMemoryCache()
            });
            return new Promise((resolve, reject) => {
              client.query({
                query: gql(tokensQuery)
              })
                .then(function (data) {
                  if (data.data.token.symbol) {
                    let result = {
                      disabled: false,
                      name: data.data.token.name,
                      description: data.data.token.name,
                      symbol: data.data.token.symbol,
                      txCount: data.data.token.txCount,
                      decimals: data.data.token.decimals,
                      totalSupply: data.data.token.totalSupply,
                      volumeETH: data.data.token.volume,
                      volumeUSD: data.data.token.volumeUSD,
                      priceUSD: data.data.token.volumeUSD / data.data.token.volume,
                      priceETH: (data.data.token.volumeUSD / data.data.token.volume) * context.state.eth,
                      liquidityETH: data.data.token.liquidity,
                      liquidityUSD: data.data.token.liquidity * context.state.eth,
                      sampleChartID: spN + ":" + data.data.token.symbol + "WETH"
                    };
                    resolve(result)
                  }
                  else {
                    let result = {
                      disabled: true,
                      log: err
                    };
                    resolve(result)
                  }
                })
                .catch(err => {
                  let result = {
                    disabled: true,
                    log: err
                  };
                  resolve(result)
                })

              if (true === false) { reject("arbitrary") }

            });
          }

          async function getTodayStats(a) {
            queueCount++
            const APIURL = "https://api.thegraph.com/subgraphs/name/sushiswap/exchange";
            const tokensQuery = `
              query tokenDayDatasQuery {
                tokenDayDatas(first: 1000, orderBy: "date", orderDirection: "desc", where: {token_in: [${a}], date_gt: 0}) {
                  id
                  date
                  token {
                    id
                    __typename
                  }
                  volumeUSD
                  liquidityUSD
                  priceUSD
                  txCount
                  __typename
                }
              }
            `
            const client = new ApolloClient({
              uri: APIURL,
              cache: new InMemoryCache()
            });
            client.query({
              query: gql(tokensQuery)
            })
              .then(function (data) {
                completedCount++
              })
              .catch(err => {
                queueCount--
                console.log(err)
                context.commit('setLoadedMessage', err);
              })
          }

          async function iterate() {
            let i = 0
            let querableAddresses = ``
            for (const element of payload) {
              if (element.disabled !== "true") { querableAddresses += `"${element.address}",` }
            }
            await getTodayStats(querableAddresses)

            for (const element of payload) {
              if (element.disabled !== "true") {
                let p = element.type === "coin" ? element : await timeMachineQueryGraphQL(element);
                p = !p ? { disabled: true } : p
                for (const [key, value] of Object.entries(p)) {
                  payload[i].id = i + 1
                  payload[i][key] = value
                }
                completedCount++
                context.commit('setLoadedPercent', (completedCount / queueCount));
              }
              i++
              if (i == payload.length) {
                context.commit('setUpdated', time);
                callback(payload)
              }
            }
          }
          iterate()
        })
    }
  },
  async init(context) {

    context.commit("setDataCacheExpiryMS", DATA_CACHE_EXPIRY_MS)
    const time = Date.now()
    if (localStorage && localStorage.length == 0) {
      localStorage.setItem("dataCacheExpiryTimeout", DATA_CACHE_EXPIRY_MS)
      localStorage.setItem("autoDataCacheRefresh", true)
      localStorage.setItem("favourites", JSON.stringify([]))
      localStorage.setItem("marketsCache", JSON.stringify([]))
      localStorage.setItem("updated", time)
      localStorage.setItem("lastMarketIndex", "0")
    }
    context.commit("setAutoDataCacheRefresh", true);
    context.commit('setFavourites', JSON.parse(localStorage.getItem("favourites")));
    context.commit('setMarketsCache', JSON.parse(localStorage.getItem("marketsCache")));
    context.commit('setUpdated', Number(localStorage.getItem("updated")));
    const n = Number(localStorage.getItem("lastMarketIndex"))
    context.commit('setCurrentMarketIndex', n);
    context.dispatch("getPriceEth")
    context.dispatch("getTokenHistory")
    context.dispatch("getMarkets")
    if (!localStorage.getItem("autoDataCacheRefresh")) {
      context.commit("setAutoDataCacheRefresh", false)
      context.dispatch("disableDataCacheRefresh")
      console.log("Data cache auto-refresh Disabled")
    } else {
      context.commit("setAutoDataCacheRefresh", true)
      // context.dispatch("setDataCacheRefreshTimeout")
    }
  },


  // EXTERNAL
  // setDataCacheRefreshTimeout(context) {
  //   const timeout = window.DATA_CACHE_EXPIRY_MS
  //   console.log("This is the timeout: ", timeout)
  // },
  disableDataCacheRefresh() {
    clearTimeout(window.dataCacheRefreshTimeout);
    window.removeEventListener("focus", function () { window.globalMarketsVuexStore.$store.dispatch("markets/getMarkets") });
  }

}